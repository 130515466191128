import React from "react"
import Layout from "../components/layout"

export default function Contact() {
    return (
        <Layout>
            <h1>Contact us</h1>
            <p>
                <a href="mailto: inquiry@spheemo.com">inquiry@spheemo.com</a>
            </p>
        </Layout>
    )
}